import React from 'react';

import {
    Card,
    Col,
    Row,
    Container,
} from "reactstrap";

import SocialLinks from "../components/SocialLinks";
import { contactInfo } from "../portfolio";

const GithubProfileCard = ({prof}) => {
    return ( 
            <Card className="section-lg bg-gradient-default shadow-lg border-0">
                <Container className="" id="contact">
                <div className="p-2">
                  <Row className="">
                  <Col className="order-lg-2" lg="4">
                      <img src={prof.avatar_url} style={{width: "200px"}} alt="" className="rounded-circle img-center img-fluid shadow shadow-lg--hover mb-4"/>
                    </Col>
                    <Col lg="8" className="order-lg-1">
                      <h2 className="text-white">
                        Reach Out to me! 
                      </h2>
                      <p className="lead text-white mt-3">
                        DISCUSS A PROJECT OR JUST WANT TO SAY HI? MY INBOX IS OPEN FOR ALL
                      </p>
                      <p className="text-white text-center mt-3">
                      <a className="text-white mt-3 contact-detail"   href={'tel:' + contactInfo.mobile}>
                        {contactInfo.mobile}
                      </a>
                      </p>
                        <SocialLinks />
                    </Col>                    
                  </Row>
                </div>
                </Container>
              </Card>
     );
}
 
export default GithubProfileCard;