import React from 'react';

import {  Button } from "reactstrap";

import { socialLinks, contactInfo } from "../portfolio";

const SocialLinks = () => {
    return ( 
        <div className="btn-wrapper text-center text-lg">
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="facebook"
                  href={socialLinks.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-facebook-square" />
                  </span>
                </Button>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="instagram"
                  href={`mailto:${socialLinks.google}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-envelope" />
                  </span>
                </Button>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="github"
                  href={socialLinks.github}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-github" />
                  </span>
                </Button>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="twitter"
                  href={socialLinks.linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-linkedin" />
                  </span>
                </Button>
                {/* <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="youtube"
                  href={socialLinks.youtube}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-youtube-play" />
                  </span>
                </Button> */}
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  href={socialLinks.medium}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-medium" />
                  </span>
                </Button>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="whatsapp"
                  href={contactInfo.whatsApp}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-whatsapp" />
                  </span>
                </Button>
        </div>
     );
}
 
export default SocialLinks;