import emoji from "react-easy-emoji";

import hivel from "./assets/img/icons/common/hivel.jpeg";
import pega from "./assets/img/icons/common/pega.png";
import dbslogo from "./assets/img/icons/common/dbs.jpg";
import gtalogo from "./assets/img/icons/common/gta.jpg";

//SEO Related settings
export const seo = {
	title: "Manish Kumar Sadhu | Full Stack Developer",
	description:
		"A passionate Full Stack developer loves to build applications with JavaScript / Reactjs / Nodejs / Java / Python and some other excellent libraries and Cross-Platform Mobile Apps With React Native.",
	og: {
		title: "Manish Kumar Sadhu Portfolio",
		type: "website",
		url: "https://manishsadhu.dev/",
	},
};

export const greetings = {
	name: "Manish Sadhu",
	title: "Hi, I'm Manish Sadhu",
	description:
		"A passionate Full Stack developer loves to build applications with JavaScript / Reactjs / Nodejs / Java / Python and some other excellent libraries and Cross-Platform Mobile Apps With React Native.",
	resumeLink: "https://manishsadhu.dev",
};

export const openSource = {
	githubUserName: "Manish-Kumar-Sadhu",
};

export const contact = {};

export const socialLinks = {
	facebook: "https://www.facebook.com/sadhumanishkumar",
	github: "https://github.com/Manish-Kumar-Sadhu",
	linkedin: "https://www.linkedin.com/in/manish-kumar-sadhu/",
	google: "sadhumanishkumar@gmail.com",
	medium: "https://medium.com/@sadhumanishkumar",
	youtube: "https://www.youtube.com/@devsadhu"
};

export const skillsSection = {
	title: "What I do",
	subTitle:
		"PASSIONATE FULL STACK DEVELOPER EXPLORING DIFFERENT TECH STACKS",
	skills: [
		emoji(
			"⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
		),emoji(
			"⚡ Develop robust backend services and enabling REST API"
		),
		emoji(
			"⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"
		),
		emoji(
			"⚡ Integration of third party services such as Firebase/ AWS"
		),
	],

	softwareSkills: [
		{
			skillName: "html-5",
			fontAwesomeClassname: "vscode-icons:file-type-html",
		},
		{
			skillName: "css3",
			fontAwesomeClassname: "vscode-icons:file-type-css",
		},
		{
			skillName: "sass",
			fontAwesomeClassname: "logos:sass",
		},
		{
			skillName: "bootstrap",
			fontAwesomeClassname: "logos:bootstrap",
		},
		{
			skillName: "JavaScript",
			fontAwesomeClassname: "logos:javascript",
		},
		{
			skillName: "jQuery",
			fontAwesomeClassname: "fontisto:jquery",
		},
		{
			skillName: "TypeScript",
			fontAwesomeClassname: "logos:typescript-icon",
		},
		{
			skillName: "reactjs",
			fontAwesomeClassname: "vscode-icons:file-type-reactjs",
		},
		{
			skillName: "nodejs",
			fontAwesomeClassname: "logos:nodejs-icon",
		},
		{
			skillName: "java",
			fontAwesomeClassname: "vscode-icons:file-type-java",
		},
		{
			skillName: "spring-boot",
			fontAwesomeClassname: "logos:spring-icon",
		},
		{
			skillName: "react-native",
			fontAwesomeClassname: "logos:react",
		},
		{
			skillName: "npm",
			fontAwesomeClassname: "vscode-icons:file-type-npm",
		},
		{
			skillName: "sql-database",
			fontAwesomeClassname: "vscode-icons:file-type-sql",
		},
		{
			skillName: "mongoDB",
			fontAwesomeClassname: "vscode-icons:file-type-mongo",
		},
		{
			skillName: "jenkins",
			fontAwesomeClassname: "logos:jenkins",
		},
		{
			skillName: "aws",
			fontAwesomeClassname: "logos:aws",
		},
		{
			skillName: "firebase",
			fontAwesomeClassname: "logos:firebase",
		},
		{
			skillName: "python",
			fontAwesomeClassname: "logos:python",
		},
		{
			skillName: "git",
			fontAwesomeClassname: "logos:git-icon",
		},
		{
			skillName: "docker",
			fontAwesomeClassname: "logos:docker-icon",
		},
	],
};

export const SkillBars = [
	{
		Stack: "Frontend Development", //Insert stack or technology you have experience in
		progressPercentage: "75", //Insert relative proficiency in percentage
	},
	{
		Stack: "Backend Development",
		progressPercentage: "90",
	},
	{
		Stack: "Mobile App Development",
		progressPercentage: "70",
	},
	{
		Stack: "Programming",
		progressPercentage: "80",
	},
];

export const educationInfo = [
	{
		schoolName: "Harvard University",
		subHeader: "Master of Science in Computer Science",
		duration: "September 2017 - April 2019",
		desc: "Participated in the research of XXX and published 3 papers.",
		descBullets: [
			"Lorem ipsum dolor sit amet, consectetur adipdfgiscing elit",
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		],
	}
];

export const experience = [
	{
		role: "Senior FullStack Developer",
		company: "Hivel",
		companylogo: hivel,
		date: "October 2023 – Present",
		desc: "",
	},
	{
		role: "Software Engineer",
		company: "PegaSystems",
		companylogo: pega,
		date: "May 2021 – October 2023",
		desc: "",
	},
	{
		role: "Software Engineer",
		company: "DBS",
		companylogo: dbslogo,
		date: "July 2019 – Apr 2021",
		desc: "",
	},
	// {
	// 	role: "Full Stack Developer",
	// 	company: "GreatToAwesome",
	// 	companylogo: gtalogo,
	// 	date: "Dec 2018 – May 2019",
	// 	desc: "",
	// 	descBullets: [

	// 	],
	// },
];

export const voluteeringProjects = [
	{
		name: "Health4all",
		desc: "Health4all is an Open Source Hospital Management System. I have developed multiple dashboards, reports module end-to-end and contributed to several other modules",
		github: "https://github.com/UCDS/health4all_v3",
		link: "https://health4all.website/",
	},
	{
		name: "Sai Liquid Love",
		desc: "Sai Liquid Love is a world community of volunteers working relentlessly to save lives and spreading love through blood donations. Developed admin dashboard to view the stats on fly",
		link: "https://www.sailiquidlove.org",
	},
	{
		name: "Health4all Today",
		desc: "Health4All_Today, a free and Open Source application, is an effort to bring basic health education to all, accessible in various languages, in a quiz based. Managing the end-to-end development and maintenance",
		github: "https://github.com/UCDS/health4all_today",
		link: "https://health4all.today/",
	},
	{
		name: "Equipment4seva",
		desc: "Equipment4Seva, a free and Open Source application, an application to track shared equipment across various non-profit organisations. Designed, architected, managing the end-to-end development and maintenance as part of volunteering activity at Yousee",
		github: "https://github.com/UCDS/equipment4seva_v2",
		link: "https://equipment4seva.one/",
	},
];

export const feedbacks = [
	{
		name: "Bhaskar Reddy Gunna",
		feedback:
			"We have been very happy with our new project! Our work experience with  Manish Sadhu has been great. He handled things very efficiently and is available for any questions we have. I would recommend to choose Manish for building web applications.",
	},
];

export const contactInfo = {
	mobile: "+91-9492807348",
	email: "sadhumanishkumar@gmail.com",
	whatsApp: "https://api.whatsapp.com/send?phone=919492807348"
}
